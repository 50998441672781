<template>
  <div :class="$style.wrap">
    <Back>
      <i
        :class="[$style.star, { [$style.active]: collected }]"
        @click.stop="handleSwitchStar"
      ></i>
    </Back>
    <!-- 个人信息 -->
    <div :class="$style.info">
      <img :class="$style.avatar" :src="detail.headimg" />
      <p :class="$style.name">{{ detail.name }}</p>
      <div :class="$style.base">
        <div :class="$style.baseItem">
          <span :class="$style.count">{{ detail.product_number }}</span>
          <span>作品库</span>
        </div>
        <div :class="$style.vertical"></div>
        <div :class="$style.baseItem">
          <span :class="$style.count">{{ detail.fans_number }}</span>
          <span>粉丝</span>
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <van-tabs
        v-model="active"
        background="#0e0e0e"
        :class="$style.tab"
        color="#fff"
        title-inactive-color="#8E8E95"
        title-active-color="#fff"
      >
        <van-tab title="作品库">
          <van-list
            :class="$style.list"
            v-model="loading"
            :finished="finished"
            :error.sync="error"
            error-text="请求失败，点击重新加载"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <WorksCard
              :class="$style.listItem"
              v-for="item in list"
              :key="item.id"
              :info="item"
            />
          </van-list>
        </van-tab>
        <van-tab title="简介" :class="$style.intro">{{
          detail.introduction
        }}</van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import WorksCard from "@/components/WorksCard";
import { fetchArtistDetail, followArtist, fetchArtistDdcList } from "@/api";
export default {
  components: { Back, WorksCard },
  data() {
    return {
      id: this.$route.params.id,
      active: 0, // 0是作品库，1简介
      detail: {}, // 详情
      collected: false,
      list: [],
      loading: false,
      finished: false,
      error: false,
      pageSize: 15,
      page: 1,
      total: 0,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const data = {
        artist_id: this.id,
      };
      fetchArtistDetail(data).then((res) => {
        this.detail = res;
        this.collected = Number(res.is_followed) === 1;
      });
    },
    handleSwitchStar() {
      followArtist({ artist_id: this.detail.id }).then(() => {
        this.collected = !this.collected;
        const msg = this.collected ? "已收藏" : "取消收藏";
        this.$toast(msg);
      });
    },
    getList() {
      const data = {
        artist_id: this.id,
        page: this.page,
        page_size: this.pageSize,
      };
      fetchArtistDdcList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.total = total;
          this.list = [...this.list, ...list];
          this.loading = false;
          this.page = page + 1;
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    onLoad() {
      this.getList();
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  .star {
    margin-right: 24px;
    background-image: url("~@/assets/img/star.png");
    background-size: contain;
    width: 40px;
    height: 40px;
    &.active {
      background-image: url("~@/assets/img/star-active.png");
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 330px;
    .avatar {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      margin: 56px 0 24px 0;
    }
    .name {
      font-size: 24px;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .base {
      margin-bottom: 24px;
      display: flex;
      .baseItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #8e8e95;
        /* &:nth-child(1) {
          margin-right: 40px;
        } */
        .count {
          font-size: 24px;
          color: #ffffff;
          line-height: 24px;
          background: linear-gradient(229deg, #8aecff 0%, #f3acff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 8px;
        }
      }
      .vertical {
        width: 1px;
        height: 16px;
        background: #ffffff;
        border-radius: 1px;
        opacity: 0.1;
        margin: 12px 20px 0;
      }
    }
  }
  .content {
    height: calc(100vh - 370px);
    background: #0e0e0e;
    border-radius: 28px 28px 0 0;
    .tab {
      background: #0e0e0e;
      border-radius: 28px 28px 0 0;
      :global {
        .van-tab {
          padding: 0;
          font-weight: 500;
        }
        .van-tab__text {
          font-size: 16px;
          font-weight: 500;
        }
      }
      padding: 0 32px;
      .list {
        margin-top: 16px;
        .listItem {
          margin-bottom: 27px;
        }
      }
      .intro {
        padding-top: 20px;
        font-size: 14px;
        color: #ccccd6;
        line-height: 20px;
      }
    }
  }
}
</style>
